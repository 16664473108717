/* Colour Palette using (https://huemint.com/ & http://colormind.io/) */
:root {
  --Main: #0f2f56;
  --OffsetDark: #0e2235;
  --OffsetLight: #073c64;
  --Contrast: #fcfcfc;
  --Accent: #f81a12;
  --Black: #121212;
  --White: #F2F4F8;
}

/* Styling */
/* Global */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background: radial-gradient(circle, var(--OffsetDark) 0%, var(--Main) 100%);
}

.bodyDiv {
  color: var(--Contrast);
  margin: auto;
  padding: 1% 5% 5% 5%;
  max-width: 1152px;
  background-color: var(--Main);
}

.bodyDiv a{
  color: var(--Contrast);
}


/* Leetcode Logo Profile Button */
.leetcodeLogo{
  width:30px
}

.leetcodeButton {
  background-color: var(--Contrast);
  border: 1px solid var(--OffsetDark);
  border-radius: 6px;
  box-sizing: border-box;
  color: var(--Black);
  cursor: pointer;
  font-size: 25px;
  padding: 7px 12px 7px;
}

.leetcodeButton:hover {
  background-color: var(--White);
  text-decoration: none;
  transition-duration: 0.1s;
}

/* Github Logo Code Button */
.githubLogo{
  width: 20px;
}

.codeButton {
  background-color: var(--Contrast);
  border: 1px solid var(--OffsetDark);
  border-radius: 6px;
  box-sizing: border-box;
  color: var(--Black);
  cursor: pointer;
  font-size: 20px;
  padding: 7px 12px 7px;
}

.codeButton:hover {
  background-color: var(--White);
  text-decoration: none;
  transition-duration: 0.1s;
}

/* Nav Bar */
.topnav {
  overflow: hidden;
  background-color: var(--OffsetDark);
  text-align: center;
}

.navitem{
  display: inline-block;
  color: var(--Contrast);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.navitem:hover {
  background-color: var(--OffsetLight);
}

.topnav .icon {
  display: none;
}

/* Accordion */
.accordion {
  border: 1px solid var(--OffsetDark);
  border-radius: 2px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid var(--Black);
}

.accordion__button {
  background-color: var(--OffsetDark);
  color: var(--Contrast);
  cursor: pointer;
  padding: 18px;
  padding-right: 0px;
  width: 99%;
  text-align: left;
  border: none;
}

.accordion__button:hover {
  background-color: var(--OffsetLight);
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px;
  animation: fadein 0.35s ease-in;
  background-color: var(--Main);
  color: var(--Contrast);
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}